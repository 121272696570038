import {
    ProjectBox,
    ProjectImageWrapper,
    DescriptionStrong,
    ProjectRow
} from "./project.styles";

import { useState } from "react";

import pic from './photos/omnifood-logo.png'
import git from '../../../assets/icons8-git-50.png'
import { check,code,links,info,gitBash } from "../../Styles/small-svg";

const picture = {
    width:'300px',
    position:'absolute',
    top:'20px',
    // backgroundColor:'blue',
    // marginTop:'0px',
    // marginBottom:'0px'
}

const wrapper = {
    border:`solid 1px #003300`,
    // baseColor:'#003300',
}

// const gitStyle = {
//     width:'25px',
//     margin:'auto'
// }

const OmniFood = () => {

    const [showInfo,setShowInfo] = useState(false)

    const tools = ['Reactjs','Nodejs','PostgreSQL']

    const mappedTools = tools.map((el,i) => {
        return <li key={i}>{check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox>
            <a href="#" target="_blank">
                <ProjectImageWrapper style={wrapper}>
                    <img style={picture} src={pic} />
                </ProjectImageWrapper>
            </a>
            {!showInfo && <DescriptionStrong style={{margin:'auto'}}>International prepared food supplier</DescriptionStrong>}
            {!showInfo ?
            <i>In developement</i>
            :
            <ul>
                {mappedTools}
            </ul>
            }
            <ProjectRow>
                    {/* <a
                        href=""
                        target="_blank"
                    >
                        {gitBash()}
                    </a> */}

                    {/* <a
                        href="https://github.com/Nefari0/data-structures"
                        target="_blank"
                    >
                        {links()}
                    </a> */}

                    <div onClick={() => setShowInfo(!showInfo)}>
                        {info()}
                    </div>
                </ProjectRow>
        </ProjectBox>
    )
}

export default OmniFood