import { 
    ProjectBox,
    ProjectImageWrapper,
    DescriptionStrong,
    ProjectRow
} from "./project.styles";

import { useState } from "react";

import { palette } from "../../Styles/colors";
import pic from './photos/crwn-192x192.png'
import { code,check,gitBash,info,links } from "../../Styles/small-svg";

const { darkText } = palette

const picture = {
    width:'60px',
    position:'absolute',
    top:'10px',
    left:'10px',
    // background: 'linear-gradient(90deg,#5077be,#6495ed)',
    // backgroundColor:'#6495ed',
    borderRadius:'10px'
}

const wrapper = {
    border:`solid 1px ${darkText}`,
    // baseColor:'#003300',
}

const h2 = {
    position:'absolute',
    left:'115px',
    top:'-5px',
    // background-color:blue;
    color:`${darkText}`
}

const Wearables = () => {

    const [showInfo,setShowInfo] = useState(false)

    const tools = ['Reactjs','Nodejs','PostgreSQL','Firebase',"Redux","WebSocket"]

    const mappedTools = tools.map((el,i) => {
        return <li key={i}>{check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox>
            <a href="http://wearables.madmodels3d.com/" target="_blank">
                <ProjectImageWrapper style={wrapper}>
                    <img style={picture} src={pic} />
                    <h2 style={h2}>Wearables</h2>
                </ProjectImageWrapper>
            </a>
            {!showInfo && <DescriptionStrong style={{margin:'auto'}}>Premium apparel </DescriptionStrong>}
            {!showInfo ?
            <i>In developement</i>
            :
            <ul>
                {mappedTools}
            </ul>}
                <ProjectRow>
                    {/* <a
                        href="https://github.com/Nefari0/3dLabWorks"
                        target="_blank"
                    >
                        {gitBash()}
                    </a> */}

                    {/* <a
                        href="https://github.com/Nefari0/data-structures"
                        target="_blank"
                    >
                        {links()}
                    </a> */}


                    <div onClick={() => setShowInfo(!showInfo)}>
                        {info()}
                    </div>
                </ProjectRow>
        </ProjectBox>
    )
}

export default Wearables