import {
    ProjectBox,
    ProjectImageWrapper,
    DescriptionStrong,
    ProjectRow
} from "./project.styles";

import { useState } from "react";

import { palette } from "../../Styles/colors";
import pic from './photos/neurons192.png'
import git from '../../../assets/icons8-git-50.png'
import { chain, check,code,links,info,gitBash } from "../../Styles/small-svg";

const { darkText } = palette

const picture = {
    width:'75px',
    position:'absolute',
    top:'0px',
    // backgroundColor:'blue',
    // marginTop:'0px',
    // marginBottom:'0px'
}

const wrapper = {
    border:`solid 1px #00c3d8`,
    background: 'linear-gradient(97deg,white 30%,#00c3d8 20%,rgba(0,0,0,.7))'
    // backgroundColor:'#003300',
}

const gitStyle = {
    width:'25px',
    margin:'auto'
}

const h2 = {
    fontWeight:'200',
    position:'absolute',
    left:'95px',
    top:'-10px',
    color:`${darkText}`
    // backgroundColor:'blue',
    // background: 'linearGradient(97deg,white 20%,#00c3d8 20%,rgba(0,0,0,.7))'
}

const DataStructures = () => {

    const [showInfo,setShowInfo] = useState(false)

    const tools = ['Reactjs','Nodejs','PostgreSQL','Firebase',"Redux","WebSocket"]

    const mappedTools = tools.map((el,i) => {
        return <li key={i}>{check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox>
            <a href="http://data.madmodels3d.com/#/" target="_blank">
                <ProjectImageWrapper style={wrapper}>
                    <img style={picture} src={pic} />
                    <h2 style={h2}>Data Structures</h2>
                </ProjectImageWrapper>
            </a>
            <DescriptionStrong style={{margin:'auto'}}>Managing data and workflow for powerful predictions</DescriptionStrong>

            {!showInfo ?
            <i>Prototyping</i>
            :
            <ul>
                {mappedTools}
            </ul> 
            }

                <ProjectRow>
                    <a
                        href="https://github.com/Nefari0/data-structures"
                        target="_blank"
                    >
                        {gitBash()}
                    </a>

                    <a
                        href="http://data.madmodels3d.com"
                        target="_blank"
                    >
                        {links()}
                    </a>

                    <div onClick={() => setShowInfo(!showInfo)}>
                        {info()}
                    </div>
                </ProjectRow>

        </ProjectBox>
    )
}

export default DataStructures