import { ProjectBox,ProjectImageWrapper,DescriptionStrong,ProjectRow } from "../MyWork/Project/project.styles";
import { JuPlexLogo } from "./notebooks.styles";
// import { ProjectBox,ProjectImageWrapper,DescriptionStrong,ProjectRow } from "./notebooks.styles";
import * as smallSvg from "../Styles/small-svg";
import { useState } from "react";
const TheNoteBook = () => {

    const [showInfo,setShowInfo] = useState(false)

    const tools = ['Python','Numpy','Sympy','Tensorflow',"Scikit-learn","Pandas",'Scipy','Matplotlib','SQL Alchemy']

    const picture = {
        width:'300px',
        position:'absolute',
        top:'-60px',
        // backgroundColor:'blue',
        // marginTop:'0px',
        // marginBottom:'0px'
    }

    const wrapper = {
        border:`solid 1px #003300`,
        // baseColor:'#003300',
    }

    const mappedTools = tools.map((el,i) => {
        return <li key={i} >{smallSvg.check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox style={{margin:'auto'}}>
        <a href="http://jupyter.madmodels3d.com/#/" target="_blank">
            <ProjectImageWrapper style={wrapper}>
                {/* <img style={picture} src={pic} alt="Brittany's Cullinary Creations"/> */}
                <JuPlexLogo>The Jupyter Complex</JuPlexLogo>
            </ProjectImageWrapper>
        </a>
        {!showInfo && <DescriptionStrong style={{margin:'auto'}}>Mathematics, Machine learning, R&D journal</DescriptionStrong>}

        {showInfo ?
        <ul>
            {mappedTools}
        </ul>
        :
        <i>Ongoing</i>
        }

            <ProjectRow>
                {/* <a
                    href="https://github.com/Nefari0/bcwb"
                    target="_blank"
                >
                    {smallSvg.gitBash()}
                </a> */}

                <a
                    href="http://jupyter.madmodels3d.com"
                    target="_blank"
                >
                    {smallSvg.links()}
                </a>

                {/* {links()} */}
                <div onClick={() => setShowInfo(!showInfo)}>
                {smallSvg.info()}
                </div>
            </ProjectRow>
        

        {/* <a
            style={{margin:'auto'}}
            href="https://github.com/Nefari0/bcwb"
            target="_blank"
        >
            <ProjectRow>
                {info()}
            </ProjectRow>
        </a> */}

    </ProjectBox>
    )
}

export default TheNoteBook