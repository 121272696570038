import styled from "styled-components";
import { palette } from "../Styles/colors";

const { lightBlue,deepBlue } = palette

export const FootContainer = styled.footer`
    // border-top:solid 1px;
    width:100vw;
    height:200px;
    // position:absolute;
    bottom:0px;
    // background-color:${deepBlue};
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
    display:flex;
    flex-direction:column;
    align-items:center;
`