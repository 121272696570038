import styled, { css,keyframes } from "styled-components";
// import { size,boardPlacement } from "../rowz.plugin";
import { palette } from "../Styles/colors";

const {
    appBackground,
    darkText,
    white,
    deepBlue,
    lightBlue,
    
    lightGreen,
    midGreen,
    darkGreen,
} = palette

// const {
//     multiplier,
//     dimensions 
// } = size

// const cubeWidth = 300;
const cubeWidth = 455
const cubeHeight = 600;
const top = -200
// height:550px;
// width:350px;

// const Rotate = keyframes`
// from {
//   transform: rotateY(0deg);
// }
// to {
//   transform: rotateY(360deg);
// }
// `;

const changSize = (scale) => {
    const newStyle = css`
      -webkit-transform: scale(${scale});
      -ms-transform: scale(${scale});
      transform: scale(${scale});
      bottom:${top*(scale)}px;
      // top:-.5px;
    `
    return (newStyle)
}

export const CubeContainer = styled.div`
    min-width: ${cubeWidth}px;
    height: ${cubeHeight}px;
    bottom:${top}px;
    position:absolute;
    -webkit-perspective: 1600px;
    -webkit-perspective-origin: 50% -240px;
    
    @media (max-width:710px) {
      ${changSize(.5)}
  }

  
  @media (max-width:450px) {
    ${changSize(.45)}
  }

  @media (max-width:400px) {
    ${changSize(.4)}
}

@media (max-width:370px) {
  ${changSize(.35)}
}

// @media (max-width:350px) {
//   ${changSize(.7)}
// }

// @media (max-width:330px) {
//   ${changSize(.65)}
// }

// @media (max-width:310px) {
//   ${changSize(.6)}
// }

// @media (max-width:280px) {
//   ${changSize(.55)}
// }


`;

export const Cube = styled.div`
  -webkit-transform-style: preserve-3d;
  margin:auto; 
  transform:
    // rotateX(-45deg)  
    // rotateX(-20deg)
    rotateY(${({ yRotation }) => yRotation}deg)
  ; 
//   transform:rotateY(${({ yRotation }) => yRotation}deg); 

  transition: all 1000ms;
  height:${cubeHeight}px;
  width:${cubeWidth};
//   background-color:red;
  // z-index:0;
//   pointer-events:none;
`;

const tableEdge = styled.figure`
    // opacity:.1;
    background-size: contain;
    background-color:${appBackground};
    display: block;
    position: absolute;
    width: ${cubeWidth}px;
    border: 2px solid ${darkGreen};
    left:-41px;
    top:-18px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

export const Front = styled(tableEdge)`
  height: ${cubeHeight}px;
//   background-color: yellow;
  -webkit-transform: translateZ(${cubeWidth/2}px);
`;

export const Back = styled(tableEdge)`
  height: ${cubeHeight}px;
//   background-color: pink;
  -webkit-transform: rotateY(180deg) translateZ(${cubeWidth / 2}px);
`;

export const Top = styled(tableEdge)`
    -webkit-transform-style: preserve-3d;
    // background-color: green;
    height: ${cubeWidth}px;
    // pointer-events:none;
    -webkit-transform: rotateX(90deg) translateZ(228px);
`;

export const Bottom = styled(tableEdge)`
  height: ${cubeWidth}px;
// height:550px;
// width:300px;
//   width: ${cubeWidth}px;
-webkit-transform: rotateX(90deg) translateZ(-372px);
  // -webkit-box-shadow: 0 10px 100px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 10px 100px rgba(0, 0, 0, 0.7);
//   box-shadow: 0px 5px 20px -1px #000000;
//   background-color: rgba(0, 0, 0, 0);
`;

export const Left = styled(tableEdge)`
//   background-color: purple;
  height: ${cubeHeight}px;
  -webkit-transform: rotateY(-90deg) translateZ(228px);
`;

export const Right = styled(tableEdge)`
//   background-color: orange;
  height: ${cubeHeight}px;
  -webkit-transform: rotateY(90deg) translateZ(228px);
`;