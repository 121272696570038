import { IntroBody,IntroPage } from "../Styles/intro.styles";
import TheNoteBook from "./notebook.item";
import { NoteBookPage,NoteBookBody } from "./notebooks.styles";
// import PyLogo from './Photos/images.png'
import PyLogo from './Photos/kisspng-python-mysql-yellow-dog-updater-modified-django-d-fierce-python-cliparts-5ab7c9de9cc3c3.9641996115219942066421.png'
import logo from '../assets/logo192.png'

const pyLogoStyle = {
    width:'150px',
    marginTop:'20px',
    // position:'absolute'
}


const MyNotebooks = () => {

    return (
        <NoteBookPage>
            {/* <img style={reactLogo} src="https://www.clipartmax.com/png/small/279-2791029_python-icon-python-logo.png" /> */}
            <img style={pyLogoStyle} src={PyLogo} />
            <p>
                {/* Machine Learning - Models for analyzing data to make powerful business predictions, written on Python. */}
                Machine learning and numerical programming in Python (Scientific programming and research journal) .
            </p>
                <h1>The Jupyter Complex</h1>
            <NoteBookBody>
                <TheNoteBook />
            </NoteBookBody>
        </NoteBookPage>
    )
}

export default MyNotebooks