import {
    ProjectBox,
    ProjectImageWrapper,
    DescriptionStrong,
    ProjectRow
} from "./project.styles";

import { useState } from "react";

import pic from './photos/bcwb.png'
import git from '../../../assets/icons8-git-50.png'
import { chain, check,code,links,info,gitBash } from "../../Styles/small-svg";

const picture = {
    width:'300px',
    position:'absolute',
    top:'-60px',
    // backgroundColor:'blue',
    // marginTop:'0px',
    // marginBottom:'0px'
}

const wrapper = {
    border:`solid 1px #003300`,
    // baseColor:'#003300',
}

// const gitStyle = {
//     width:'25px',
//     margin:'auto'
// }

const BCWB = () => {

    const tools = ['Reactjs','Nodejs','PostgreSQL','Firebase',"Redux"]

    const [showInfo,setShowInfo] = useState(false)

    const mappedTools = tools.map((el,i) => {
        return <li key={i} >{check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox>
            <a href="http://brittsrecipes.madmodels3d.com/#/" target="_blank">
                <ProjectImageWrapper style={wrapper}>
                    <img style={picture} src={pic} alt="Brittany's Cullinary Creations"/>
                </ProjectImageWrapper>
            </a>
            {!showInfo && <DescriptionStrong style={{margin:'auto'}}>A collection of fine breads and desserts</DescriptionStrong>}

            {showInfo ?
            <ul>
                {mappedTools}
            </ul>
            :
            <i>Prototyping</i>
            }

                <ProjectRow>
                    <a
                        href="https://github.com/Nefari0/bcwb"
                        target="_blank"
                    >
                        {gitBash()}
                    </a>

                    <a
                        href="http://brittsblog.madmodels3d.com"
                        target="_blank"
                    >
                        {links()}
                    </a>

                    {/* {links()} */}
                    <div onClick={() => setShowInfo(!showInfo)}>
                    {info()}
                    </div>
                </ProjectRow>
            

            {/* <a
                style={{margin:'auto'}}
                href="https://github.com/Nefari0/bcwb"
                target="_blank"
            >
                <ProjectRow>
                    {info()}
                </ProjectRow>
            </a> */}

        </ProjectBox>
    )
}

export default BCWB