import styled from "styled-components";
import { palette } from "../../Styles/colors";
import { device } from "../../Styles/queries";

const {appBackground,deepBlue,darkText,white,lightGreen,midGreen,darkGreen } = palette
const { desktop,tablet,tabletS,mobileL,mobileS,mobileM } = device

export const ProjectBox = styled.div`
    height:350px;
    width:350px;
    background-color:${white};
    box-shadow: 0px 5px 20px -7px #000000;
    border-radius:10px;
    border: solid 1px ${deepBlue};
    display:flex;
    flex-direction:column;
    justify-content:flex-start;

    ul {
        width:80%;
    }

    li {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:40%;
        height:15px;
        font-size:10px;
        color:${darkText};
        // background-color:${lightGreen};
        margin:auto;
    }

    // &:hover {
    //     background-color:red;
    //     // transform: scaleY(-1);
    //     transform: scaleX(-1);
    //     transition: transform .5s, opacity 0.5s;
    // }

    a {
        text-decoration:none;
    }

    i {
        color:${darkText};
        margin:auto;
        background-color:${appBackground};
        padding:0px 10px 0px 10px;
    }

    @media ${mobileM} {
        width:300px;
    }

    @media ${mobileS} {
        width:280px;
    }

`

export const ProjectImageWrapper = styled.div`
    // width:50px;
    position:relative;
    // border: solid 1px ${deepBlue};
    border-radius:10px;
    margin:auto;
    margin-bottom:0px;
    margin-top:40px;

    width:300px;
    height:75px;
    overflow:hidden;

    @media ${mobileM} {
        // width:270px;
        transform: scale(0.90);
    }
    
    @media ${mobileS} {
        margin-left:-10px;
        // width:270px;
        transform: scale(0.80);
    }

`

export const DescriptionStrong = styled.strong`
    color:${darkText};
    // background-color:blue;
    color:black;
    width:80%;
    margin:auto;
    margin-top:10px;
    text-align:center;
`

export const ProjectRow = styled.section`
        display:flex;
        // justify-content:space-between;
        // background-color:${midGreen};
        border-radius:10px;
        margin:auto;

        a,
        // img,
        svg {
            height:25px;
            width:25px;
            // margin:20, 20, 110, 100px;
            margin: 0px 25px 0px 25px;
            color:${deepBlue};
            // background-color:${midGreen};
            // border-radius:50%;
        }
        img {background-color:blue;width:25px;}
`