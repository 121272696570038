import { SkillBox } from "./skill.styles";
import { machine } from "../../Styles/large-svg";

const MachineLearning = () => {

    return (
        <SkillBox>
            {machine()}
            <h1>Data Scientist</h1>
            <strong>Languages/Tools:</strong>

            <ul>
                <li>Python</li>
                <li>Numpy</li>
                <li>Sympy</li>
                <li>Tensorflow</li>
                <li>Scikit-learn</li>
                <li>Pandas</li>
                <li>Scipy</li>
                <li>Matplotlib</li>
                <li>SQL Alchemy</li>
            </ul>
        </SkillBox>
    )
}

export default MachineLearning