import styled from "styled-components";
import { palette } from "../Styles/colors";
import logo from '../assets/mstile-150x150.png'
import { FootContainer } from "./foot.styles";
import PyLogo from '../MyNotebooks/Photos/PyLogo1.png'
import { NoteBookPage } from "../MyNotebooks/notebooks.styles";

const { lightBlue,deepBlue } = palette

// const FootContainer = styled.footer`
//     // border-top:solid 4px green;
//     width:100vw;
//     height:1200px;
//     // background-color:${deepBlue};
//     box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
// `

const pyLogoStyle = {
    width:'150px',
    width:'150px',
    // marginTop:'20px',
    // backgroundColor:'blue',
    marginLeft:'',
    // position:'absolute'
}

const Foot = () => {

    return (
        <FootContainer>
            {/* <h1>here is text</h1> */}
            <a
                href="http://madmodels3d.com/#/"
                target="_blank"
            >
                <img src={logo} style={pyLogoStyle} /> 
            </a>
        </FootContainer>
    )
}

export default Foot