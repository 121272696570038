// import { NoticeContainer } from "../hero.styles"
import { useContext } from "react"
import { AppContext } from "../Context/app.context"
import { NoticeContainer } from "./notice.styles"
// import { SubmitButton } from "./forminput.styles"
import { BaseButton } from "../Form/forminput.styles"
import { StaticOverlay } from "../Styles/base.styles"
import { BaseHeader } from "../Styles/base.styles"
import { closeIcon } from "../Styles/small-svg"

const Notice = () => {

    const { notice,setNotice } = useContext(AppContext)
    // const { notice } = appState

    return (
        <StaticOverlay style={{zIndex:'4'}}>
            <NoticeContainer>
                <BaseHeader>
                    <h4>Info</h4>
                    <div onClick={() => setNotice(null)}>{closeIcon()}</div>
                </BaseHeader>
                <p>{notice}</p>
                {/* <button onClick={() => setNotice(null)}>button</button> */}
                <BaseButton onClick={() => setNotice(null)}>Close</BaseButton>
            </NoticeContainer>
        </StaticOverlay>
    )
}

export default Notice