import {
    ProjectBox,
    ProjectImageWrapper,
    DescriptionStrong,
    ProjectRow
} from "./project.styles";

import { useState } from "react";

import pic from './photos/MWI-logo.png'
import { check,code,gitBash,links,info } from "../../Styles/small-svg";
// import pic from '../../../assets/d&d model.png'

const picture = {
    position:'absolute',
    width:'300px',
    // left:'-5px',
    backgroundColor:'#222222'
    
    // height:'65px',
    // borderRadius:'10px'
}

const MWI = () => {

    const [showInfo,setShowInfo] = useState(false)

    const tools = ['Reactjs','Nodejs','PostgreSQL']

    const mappedTools = tools.map((el,i) => {
        return <li key={i}>{check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox>
            <a href="http://mwi.madmodels3d.com/" target="_blank">
                <ProjectImageWrapper style={{backgroundColor:'#222222'}}>
                    <img style={picture} src={pic} />
                </ProjectImageWrapper>
            </a>
            <DescriptionStrong style={{margin:'auto'}}>Company contact module</DescriptionStrong>
            {!showInfo ?
            <i>Protoyping</i>
            :
            <ul style={{marginTop:'40px'}}>
                {mappedTools}
            </ul>
            }
            <ProjectRow>

                <a
                    href="https://github.com/Nefari0/MWI-tech-challenge"
                    target="_blank"
                >
                    {gitBash()}
                </a>

                <a
                    href="http://mwi.madmodels3d.com"
                    target="_blank"
                >
                    {links()}
                </a>

                <div onClick={() => setShowInfo(!showInfo)}>
                    {info()}
                </div>
            </ProjectRow>

        </ProjectBox>
    )
}

export default MWI