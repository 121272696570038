// import { useState } from 'react';
import { AppContainer } from './App.styles'
import HeroView from './Components/Hero/hero.component';
import MyInfo from './Components/MyInfo/myinfo.component';
import MyWork from './Components/MyWork/works.component';
import ReactModules from './Components/MyModules/module.component';
import { Sections,AppTable,Section3,Section4,Section5 } from './App.styles';
import MyNotebooks from './Components/MyNotebooks/notebooks.components';
import Foot from './Components/Footer/foot.components';
// import routes from './routes';
import { Overlay } from './Components/Styles/base.styles';
import { useContext } from 'react';
import { AppContext } from './Components/Context/app.context';
import Notice from './Components/Notice/notice.component';
import ContactMe from './Components/ContactPage/ContactInfo.component';
// import CheckerBoard from './Components/MyModules/Rowz/CheckerBoard/CheckerBoard';
// import CheckerBoard from './Components/Rowz/CheckerBoard/CheckerBoard';
function App() {
  // const currentGame = 12345
  const { notice,contact } = useContext(AppContext)
  // const { notice } = appState

  const slideDown = () => {
    window.scrollTo({
      top: (window.innerWidth < 900 ? 3500 : 2500),
      behavior: 'smooth'
  });
  }

  return (
    <AppContainer>
      {notice && <Notice />}
      {contact && <ContactMe />}
      <Sections style={{height:'400px'}}>
        <HeroView />
        {/* {routes} */}
      </Sections>
      {/* <Overlay> */}
         {/* {routes} */}
      {/* </Overlay> */}

      <Sections>
        {/* <MyInfo /> */}
        <ReactModules slideDown={slideDown} />
      </Sections>
      
      <Section3>
          <MyInfo />
        {/* <ReactModules/> */}
      </Section3>


      <Section4>
        {/* <MyNotebooks /> */}
        <MyWork />
      </Section4>
      <Section5>
        {/* <MyWork /> */}
        <MyNotebooks />
      </Section5>


    <Foot/>
    </AppContainer>
  );
}

export default App;