// import {connect} from 'react-redux'
// import { updatePlayer } from '../../redux/checkerReducer';
import cubeLogo from '../assets/mstile-150x150.png'
import {
    CubeContainer,
    Cube,
    Front,
    Back,
    Top,
    Bottom,
    Left,
    Right
  } from "./cube.styles";
//   import CurrentPlayer from '../Rows/TurnIndicator/current.component';
//   import CheckerBoard from "../Rows/CheckerBoard/CheckerBoard";
  
  const GameBoard = ({state,mappedProjects}) => {
    const { yRotation, xRotation, currentIndex } = state;

    // console.log('HERE ARE THE PROJECTS',mappedProjects)
  
    return (
      <CubeContainer>
        <Cube xRotation={xRotation} yRotation={yRotation}>
            <Top>
                {/* top */}
                {/* <CurrentPlayer 
                  currentPlayer={currentPlayer.currentPlayer.currentPlayer}
                /> */}
                {/* <CheckerBoard /> */}
                {/* <figure style={{transform:`rotateZ(${-xRotation})`}}> */}
                <div
                    style={{
                        width:'100px',
                        height:'100px',
                        margin:'auto',
                        // backgroundColor:'white',
                        transform:`rotateX(-90deg) rotateY(${yRotation}deg) translateY(-120px)`,
                        transition: "all 1000ms",
                        overflow:'hidden',
                        // boxShadow: `0px 90px 48px -40px #000000`
                    }}>
                    <img
                        style={{
                            width:'200px',
                            height:'200px',
                            position:'absolute',
                            left:'-50px',
                            top:'-30px'
                            // transform:`rotateX(-90deg) rotateY(${yRotation}deg) translateY(-120px)`,
                            // transition: "all 1000ms",
                            // backgroundColor:'blue',
                            // boxShadow: `0px 60px 28px -20px #000000`
                        }} 
                        src={cubeLogo} 
                    />
                </div>
                {/* </figure> */}
            </Top>
            <Bottom></Bottom>

            <Left>
            {mappedProjects[2]}

                {/* left */}
            </Left>

            <Front>
            {mappedProjects[1]}
                {/* front */}
            </Front>

            <Right>
            {mappedProjects[0]}
                {/* right */}
            </Right>

            {/* <figure></figure> */}
            <Back>
            {mappedProjects[3]}
                {/* back */}
            </Back>
        </Cube>
      </CubeContainer>
    );
  };

// function mapStateToProps(reduxState){
//     return reduxState
// }

// export default connect(mapStateToProps, {updatePlayer})(GameBoard)
  
  export default GameBoard;
  