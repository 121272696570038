import styled from "styled-components";
import { palette } from "../../Styles/colors";
import { device } from "../../Styles/queries";

const { appBackground,darkText } = palette
const { desktop,tablet,mobileL,mobileS } = device

export const SkillBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    // justify-content:center;
    align-items: center;
    align-content: center;
    width:350px;
    height:600px;
    background-color:${appBackground};
    color:${darkText};
    margin:auto;
    box-shadow: 0px 5px 20px -7px #000000;
    border-radius:10px;

    h1 {
        font-size:24px;
        // width:100%;
        // background-color:yellow;
        // margin:auto;
    }

    bold {
        font-size:20px;
        font-weight:bold;
        color:${darkText};
    }

    li {
        list-style:none;
        // background-color:blue;
        margin-left:-40px;
        margin-top:5px;
    }

    @media ${desktop} {
        width:200px;
    }

    @media ${tablet} {
        position:relative;
        width:400px;
        // margin:auto;
    }

    @media ${mobileL} {
        position:relative;
        width:300px;
        // margin:auto;
    }

    @media ${mobileS} {
        position:relative;
        width:280px;
        // margin:auto;
    }
`