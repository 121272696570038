import { createContext,useState } from "react";

export const AppContext = createContext({
    notice:null,
    setNotice:() => null,

    contact:false,
    setContact:() => null
})

export const AppProvider = ({children}) => {
    const [notice,setNotice] = useState(null)
    const [contact,setContact] = useState(false)

    const value = {
        contact,setContact,
        notice,setNotice
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}