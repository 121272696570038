import styled from "styled-components";
import { palette } from "./colors";
import { size } from "./queries";
import { device } from "./queries";

const { white,deepBlue } = palette
const { desktop,tablet,mobileL,mobileS } = device

export const IntroPage = styled.section`
position:relative;
    width:100vw;
    height:600px;
    background-color:${deepBlue};
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    // padding-left:30px;
    // padding-right:30px;
    color:${white};
    // text-align:center;
    
    // h2 {
    //     font-size:30px;

    //     @media ${mobileL} {
    //         // background-color:yellow;
    //         font-size:20px;
    //         width:300px;
    //     }
    // }

    p {
        width:80vw;
        text-align:center;
        letter-spacing:1px;

        @media ${mobileL} {
            width:280px;
            // background-color:white;
        }
    }

`

export const IntroBody = styled.section`
    max-width:1400px;
    height:800px;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);

    @media ${desktop} {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap:260px;
        // overflow:auto;
    }

    @media ${tablet} {
        grid-template-columns: repeat(1, 1fr);
    }
`

export const Introh2 = styled.h2`
text-align:center;
    font-size:30px;

    @media ${mobileL} {
        // background-color:yellow;
        font-size:20px;
        width:300px;
    }

    @media ${mobileS} {
        // background-color:yellow;
        font-size:20px;
        width:280px;
    }
`