import { IntroPage,IntroBody } from "../Styles/intro.styles";
import { MyInfoPage } from "../MyInfo/myinfo.styles";
import Project from "./Project/project.components";
import MWI from "./Project/mwi.component";
import BCWB from "./Project/britsblog.component";
import MadModels from "./Project/madmodels.components";
import OmniFood from "./Project/omnifood.component";
import Wearables from "./Project/wearables.component";
import DataStructures from "./Project/data.component";
import { MyWorkPage } from "./work.styles";

const reactLogo = {
    width:'150px',
    marginTop:'20px'
    // position:'absolute'
}


const MyWork = () => {

    return (
        <MyWorkPage>
            {/* <h2>React Apps</h2> */}
            <img style={reactLogo} src='https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' />
            <p>
            Some of the major React applications I've build recently.
            </p>
                <h1>Recent React Apps</h1>
            <IntroBody>
                <MadModels />
                <BCWB />
                <MWI />
                <DataStructures />
                <OmniFood />
                <Wearables />
            </IntroBody>
            
        </MyWorkPage>
    )
}

export default MyWork