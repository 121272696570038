import styled from "styled-components";
import { palette } from "./Components/Styles/colors";
// import { size } from "./Components/MyModules/rowz.plugin";

import { device } from "./Components/Styles/queries";

// const { multiplier } = size
const { appBackground,white,deepBlue } = palette
const { desktop,tablet,tabletS,mobileL,mobileS,desktopL,mobileM } = device

export const AppContainer = styled.main`
  min-height:100vh;
  overflow:scroll;
  width:100vw;
  background-color:${appBackground};
`

export const Sections = styled.section`
  position:relative;
  height:800px;
  width:100vw;
  margin-top:100px;
  `

export const Section3 = styled(Sections)`
  margin-top:40px;

  @media ${desktop} {
    // margin-top:300px;
  }

  @media ${tablet} {
    // margin-top:1400px;
    // margin-top:1300px;
  }

`

export const Section4 = styled(Sections)`
  margin-top:140px;
  // @media ${desktop} {
    // margin-top:1640px;
    // margin-top:940px;
  // }

  // @media ${mobileL} {
  //   margin-top:1740px;
  // }

  // @media ${mobileM} {
  //   margin-top:1340px;
  // }

  // @media ${mobileS} {
  //   margin-top:1060px;
  // }
`

export const Section5 = styled(Sections)`
  margin-top:240px;
  @media ${desktop} {
    margin-top:640px;
  }

  @media ${mobileL} {
    // background-color:blue;
    margin-top:740px;
  }

  // @media ${mobileM} {
  //   margin-top:1340px;
  // }

  // @media ${mobileS} {
  //   margin-top:1060px;
  // }
`