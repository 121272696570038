import { SkillBox } from "./skill.styles";
import { tools } from "../../Styles/large-svg";

const Maker = () => {

    return (
        <SkillBox>
            {tools()}
            <h1>Maker</h1>
            <strong>Languages/Tools:</strong>

            <ul>
                <li>Blender 2.7+</li>
                <li>Python Blender API</li>
                <li>Creality</li>
                <li>Repetier</li>
                <li>Cura</li>
                <li>PBasic</li>
                <li>Atanue</li>
            </ul>
        </SkillBox>
    )
}

export default Maker