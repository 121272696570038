import { 
    ProjectBox,
    ProjectImageWrapper,
    DescriptionStrong,
    ProjectRow
} from "./project.styles";

import { useState } from "react";

import pic from './photos/madmodels.png'
import { code,check,gitBash,info,links } from "../../Styles/small-svg";

const picture = {
    width:'300px',
    position:'absolute',
    // top:'2px',
    // left:'-10px',
    background: 'linear-gradient(90deg,#5077be,#6495ed)',
    // backgroundColor:'#6495ed',
    borderRadius:'10px'
}

const MadModels = () => {

    const [showInfo,setShowInfo] = useState(false)

    const tools = ['Reactjs','Nodejs','PostgreSQL','Firebase',"Redux","WebSocket"]

    const mappedTools = tools.map((el,i) => {
        return <li key={i}>{check()}<strong>{el}</strong></li>
    })

    return (
        <ProjectBox>
            <a href="http://madmodels3d.com/#/" target="_blank">
                <ProjectImageWrapper>
                    <img style={picture} src={pic} />
                </ProjectImageWrapper>
            </a>
            {/* <h3>MadModels3d</h3> */}
            {!showInfo && <DescriptionStrong style={{margin:'auto'}}>Upload and share your digital graphics</DescriptionStrong>}
            {!showInfo ?
            <i>Shipped</i>
            :
            <ul>
                {mappedTools}
            </ul>
            }
                <ProjectRow>
                    <a
                        href="https://github.com/Nefari0/3dLabWorks"
                        target="_blank"
                    >
                        {gitBash()}
                    </a>

                    <a
                        href="http://www.madmodels3d.com"
                        target="_blank"
                    >
                        {links()}
                    </a>

                    <div onClick={() => setShowInfo(!showInfo)}>
                        {info()}
                    </div>
                </ProjectRow>
        </ProjectBox>
    )
}

export default MadModels