import styled from "styled-components";
import { palette } from "./colors";

const { deepBlue,white } = palette

export const StaticOverlay = styled.section`
    width:100vw;
    height:100vh;
    // position:absolute;
    position:fixed;
    top:0px;
    // background-color:orange;
    display:flex;
    // flex-direction:column;
    justify-content:center;
    // justify-content:flex-start;

`

export const AbsoluteOverlay = styled(StaticOverlay)`
    position:absolute;
`

export const BaseHeader = styled.header`
    width:102%;
    height:40px;
    position:absolute;
    top:-2px;
    background-color:${deepBlue};
    border: solid 2px ${deepBlue};

    h4 {
        margin:auto;
        margin-top:5px;
        width:18%;
        color:white;
        font-weight:400;
    }

    svg {
        position:absolute;
        right:10px;
        top:5px;
        color:${white};
    }
`