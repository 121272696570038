import styled from "styled-components";
import { IntroPage,IntroBody } from "../Styles/intro.styles";
import { device } from "../Styles/queries";

const { desktop,tablet,mobileL,mobileS } = device

export const MyInfoPage = styled(IntroPage)`

`

export const MyInfoBody = styled(IntroBody)`
    @media ${desktop} {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap:260px;
    }

    @media ${tablet} {
        // display:flex;
        // flex-direction:column;

        height:1300px
        background:color:white;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap:520px;
    }


`