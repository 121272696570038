import { ContactPage } from "./ContactIInfo.styles"
import { BaseHeader } from "../Styles/base.styles"
// import FormInput from "../Hero/Form/FormInput.component"
// import TextEditor from "../Hero/Form/texteditor.component"
import FormInput from '../Form/FormInput.component' 
import TextEditor from '../Form/texteditor.component'
// import { Form,SubmitButton,ContactHeader } from "../Hero/Form/forminput.styles"
import { BaseButton,Form } from "../Form/forminput.styles"
import { AbsoluteOverlay } from "../Styles/base.styles"
import { useState,useContext } from "react"
import { AppContext } from "../Context/app.context"
import { closeIcon } from "../Styles/small-svg"
import axios from 'axios'


const ContactMe = ({setContact,changeNotice}) => {
    
    const defaultState = {
        name:'',
        email:'',
        message:''
    }
                
    // useEffect(() => {console.log('CONTACT',setContact)},[])
    
    const [state,setState] = useState(defaultState)
    const { name,email,message} = state
    const { notice,setNotice} = useContext(AppContext)

    const inputHandler = (e) => {
        e.preventDefault()
        const {name,value} = e.target
        setState({...state,[name]:value})
    }

    const send = (e) => {
        e.preventDefault()
        console.log('HIT SEND FUNCTION',state)
        axios.post('/api/messages/user/add',state).then(res => {
            // changeNotice('your message has been sent')
            setNotice('your message has been sent')
            // window.location.pathname = '/'
            // setContact(false)
        })
    }

    const closeHander = () => {
        window.location.pathname = '/'
        console.log('hit close handler')
        // setContact(false)
    }

    return (
        <AbsoluteOverlay style={{zIndex:'2'}}>
            {/* <Route path="/"/> */}
            <ContactPage onSubmit={send}>
                <BaseHeader>
                    <h4>Contact</h4>
                    <div onClick={closeHander}>{closeIcon()}</div>
                </BaseHeader>
                <FormInput
                    label='Name' 
                    name='name'
                    type='text'
                    required
                    onChange={inputHandler} 
                    value={name}
                />

                
                <FormInput
                    label='Email' 
                    name='email'
                    type='text'
                    required
                    onChange={inputHandler} 
                    value={email}
                />
                

                <TextEditor
                    label='Message' 
                    name='message'
                    type='text'
                    required
                    onChange={inputHandler} 
                    value={message}
                />

                <BaseButton
                    type="submit"
                >
                    Submit
                </BaseButton>

            </ContactPage>
        </AbsoluteOverlay>
    )
}

export default ContactMe