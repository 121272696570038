import styled from "styled-components";
import { device } from "../Styles/queries";
import { IntroPage,IntroBodyody } from "../Styles/intro.styles";

const { tablet,mobileS } = device

export const MyWorkPage = styled(IntroPage)`
    margin-bottom:100px;
    height:800px;
    @media ${tablet} {
        top:1200px;
    }

    @media ${mobileS} {
        top:1250px;
    }
`