import styled, { css } from 'styled-components'
// import { colors } from '../../Styles/colors';
// import { palette } from '../Styles/colors';
// import { device } from './Styles/queries';
import { device } from '../Styles/queries';
import { palette } from '../Styles/colors';
const { desktop,mobileM } = device
const { deepBlue,lightGreen,white,darkText } = palette

const shrinkLabelStyles = css`
    top: -10px;
    font-size: 12px;
`;

export const FormInputLabel = styled.label`
    color: ${deepBlue};
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top:20px;
    transition: 300ms ease all;

    ${({ shrink }) => shrink && shrinkLabelStyles};

`;

export const Input = styled.input`
    background: none;
    background-color: #F5F5F5;
    color: ${deepBlue};
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 90%;
    height:30px;
    border: none;
    border-radius: 0;
    margin: 10px 0;

    &:focus {
      outline: none;
    }

    &:focus ~ ${FormInputLabel} {
      ${shrinkLabelStyles};
    }
`;

export const Message = styled.textarea`
    width:95%;
    height:200px;
    @media ${desktop} {width:90%;}

    background: none;
    background-color: #F5F5F5;
    color: ${deepBlue};
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    // width: 90%;
    // height:30px;
    border: none;
    border-radius: 0;
    margin: 10px 0;

    &:focus {
      outline: none;
    }

    &:focus ~ ${FormInputLabel} {
      ${shrinkLabelStyles};
    }
`;

export const Group = styled.div`
    width:100%;
    // height:0px;
    position:absolute;
    // margin:auto;
    margin:10px;
    // margin-top:-10px;
    position: relative;

    @media ${desktop} {width:100%;}
`;

export const LargeGroup = styled(Group)`

// margin-top:-50px;
// margin:auto;
    width:100%;
    
`

// export const Form = styled.form`
//     margin:auto;
//     min-height: 600px;
//     width: 570px;
//     padding:30px;
//     background-color: ${white};
//     display: flex;
//     flex-wrap: nowrap;
//     align-items: center;
//     z-index:0;

//     button {
//         margin:auto;
//     }

//     @media ${desktop} {
//         position: relative;
//         background-color: ${white};
//         width: 350px;
//         margin:auto;
//         flex-direction:column;
//         flex-wrap:nowrap;
//     }
    
// `;

export const BaseButton = styled.button`
  width:100px;
  height:50px;
  background-color:${lightGreen};
  border-radius:30px;
  border: solid 2px ${deepBlue};
  font-weight:600;
  color:${darkText};
`

export const QuietButton = styled(BaseButton)`
    background-color:#fff;
`

// export const ContactHeader = styled.header`
//     width:102%;
//     height:40px;
//     position:absolute;
//     top:-2px;
//     background-color:${deepBlue};
//     border: solid 2px ${deepBlue};

//     h4 {
//         margin:auto;
//         margin-top:5px;
//         width:18%;
//         color:white;
//         font-weight:400;
//     }

//     svg {
//         position:absolute;
//         right:10px;
//         top:5px;
//         color:${white};
//     }
// `