import styled from "styled-components"
import { palette } from "../Styles/colors"

const { white,deepBlue } = palette

export const ContactPage = styled.form`
    position:relative;

    max-width:300px;
    // min-width:280px;
    height:600px;
    // height:200px;
    background-color:${white};
    z-index:100000;
    box-shadow: 0px 5px 20px -7px #000000;
    border-radius:10px;
    // border: solid 2px ${deepBlue};
    overflow:hidden;
    
    margin:auto;
    margin-top:20px;
    min-height: 600px;

    // width: 570px;
    padding:32px;
    // background-color: ${white};
    // background-color:blue;
    display: flex;
    flex-direction:column;
    // flex-wrap: wrap;
    align-items: center;
    // z-index:0;   
    z-index:3;

    @media (max-height:700px) {
        // position:sticky;
        // top:80px;
        // top:200%;
    }
`

export const ContactHeader = styled.header`
    width:102%;
    height:40px;
    position:absolute;
    top:-2px;
    background-color:${deepBlue};
    border: solid 2px ${deepBlue};

    h4 {
        margin:auto;
        margin-top:5px;
        width:18%;
        color:white;
        font-weight:400;
    }

    svg {
        position:absolute;
        right:10px;
        top:5px;
        color:${white};
    }
`