import styled from "styled-components"
import { IntroBody } from "../Styles/intro.styles"
// import { size } from "./rowz.plugin"
import { palette } from "../Styles/colors"
import { device } from "../Styles/queries"
import { QuietButton } from "../Form/forminput.styles"

// const { multiplier } = size
const { deepBlue,appBackground,white,darkText } = palette
const { desktop,tablet,tabletS,mobileL,mobileS,desktopL,mobileM } = device


export const ModulesLayout = styled(IntroBody)`
min-height:800px;
min-width:100vw;
top:0px;
// display:flex;
// grid-template-columns: repeat(2, 1fr);
// gap: 10px;
// grid-auto-rows: minmax(100px, auto);

  // @media ${desktop} {
  //   grid-template-columns: repeat(1, 1fr);
  //   row-gap:600px;
  // }

  // @media ${mobileM} {
  //   grid-template-columns: repeat(1, 1fr);
  //   row-gap:500px;
  // }

  // @media ${mobileS} {
  //   grid-template-columns: repeat(1, 1fr);
  //   row-gap:400px;
  // }
`

export const AppTable = styled.a`
  position:relative;
  margin:auto;
  overflow:hidden;

  height:550px;
  width:400px;
  // height:450px;
  // width:350px;


  // padding:20px;
  background-color:${appBackground};
  // box-shadow: 0px 5px 20px -7px #000000;

  border-radius:10px;
  // border: solid 1px ${deepBlue};
  // display:flex;
  // flex-direction:column;
  // justify-content:center;
  // align-items:center;
  display:flex;
  flex-direction:column;
 
  a {
    text-decoration:none;
  }

  // img {
  //   pointer-events:none;
  // }

  // h1 {
  //   font-size:36px;
  //   color:${darkText};
  //   margin:0px;
  //   font-weight:200;

  //   @media ${mobileS} {
  //     font-size:30px;
  //   }
  // }

  // @media ${desktopL} {
  //   width:300px;
  //   background-color:white;
  // }

  // @media ${desktop} {
  //   width:450px;
  //   height:650px;
  // }

  // @media ${mobileL} {
  //   width:350px;
  //   height:650px;
  // }

  // @media ${mobileM} {
  //   width:300px;
  //   height:550px;
  // }

  // @media ${mobileS} {
  //   width:240px;
  //   height:450px;
  // }
`

// const graphSize = .48
const graphSize = .6
export const GraphAdapter = styled.div`
  -webkit-transform: scale(${graphSize});
  -ms-transform: scale(${graphSize});
  transform: scale(${graphSize});
  position:absolute;
  // background-color:blue;
  // left:-185px;
  // top:-180px;
  top:-205px;
  // left:20px;
  left:-145px;

  // img {
  //   position:relative;
  // }

@media ${desktopL} {
  left:-170px;
  // left:40px;
  // top:120px;
  // -webkit-transform: scale(.5);
  // -ms-transform: scale(.5);
  // transform: scale(.5);
}



  @media ${desktop} {
    // left:-140px;
    left:-94px;
    top:-140px;
    -webkit-transform: scale(${.73});
    -ms-transform: scale(${.73});
    transform: scale(${.73});
  }


@media ${mobileL} {
  left:-145px
}

@media ${mobileM} {
  left:-175px;
  top:-180px;
  -webkit-transform: scale(${.65});
  -ms-transform: scale(${.65});
  transform: scale(${.65});
}

@media ${mobileS} {
  -webkit-transform: scale(${.5});
  -ms-transform: scale(${.5});
  transform: scale(${.5});
  top:-252px;
  left:-202px;
}
`
const ticSize = .9
export const TicTacToeAddapter = styled.div`

// @media${desktopL} {

//   -webkit-transform: scale(${ticSize});
//   -ms-transform: scale(${ticSize});
//   transform: scale(${ticSize});
//   position:absolute;
//   top:30px;
//   left:-8px;
// }

// @media${desktop} {

//   -webkit-transform: scale(${1.2});
//   -ms-transform: scale(${1.2});
//   transform: scale(${1.2});
//   position:absolute;
//   top:30px;
//   left:8px;
// }

// @media ${mobileL} {
//   left:-40px
// }

// @media ${mobileM} {
//   -webkit-transform: scale(${1});
//   -ms-transform: scale(${1});
//   transform: scale(${1});
//   left:-25px
// }

// @media ${mobileS} {
//   left:-54px;
//   top:-30px;
// }
`

export const ModuleCubeContainer = styled.section`
  width:100%;
  height:300px;
  // position:relative;
  // margin:auto;
  top:150px;
  position:relative;
  // background-color:white;
  display:flex;
  justify-content:center;
  align-items:center;

  // button {
  //   margin:auto;
  //   margin-top:5%;
  //   // background-color:transparent;
  //   border:none;
  //   width:80px;
  //   height:80px;

  //   @media (max-width:700px) {
  //     margin-left:-10px;
  //   }
  // }
  // flex-direction:c;
`

const SpinButton = styled.button`
  margin:auto;
  margin-top:5%;
  background-color:transparent;
  border:none;
  width:80px;
  height:80px;
  z-index:1;
  // box-shadow: 0px 95px 38px -25px #000000; // this one is good
  // box-shadow: 0px 95px 38px -30px #000000;
  @media (max-width:700px) {
    box-shadow: 0px 95px 38px -30px #000000;
  }

  @media (max-width:360px) {
    bottom:0px;
    transform: scale(.5);
  }
`

export const RightButton = styled(SpinButton)`
  @media (max-width:700px) {
    position:absolute;
    right:0px;
  }
`

export const LeftButton = styled(SpinButton)`
  @media (max-width:700px) {
    position:absolute;
    left:0px;
   
  }
`

export const SlideDownButton = styled(QuietButton)`
  position:absolute;
  top:400px;
  height:25px;
  width:200px;
  z-index:1000000;

  @media (max-width:700px) {
    top:350px;
  }

  @media (max-width:450px) {
    top:325px;
  }

  @media (max-width:355px) {
    top:300px;
  }
`