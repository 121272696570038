import { SkillBox } from "./skill.styles";
import { console } from "../../Styles/large-svg";

const ReactDeveloper = () => {

    return (
        <SkillBox>
            {console()}
            <h1>React Developer</h1>
            <strong>Languages/Tools:</strong>

            <ul>
                <li>Javascript</li>
                <li>Reactjs</li>
                <li>Nodejs</li>
                <li>HTML5</li>
                <li>css</li>
                <li>PostgreSQL</li>
                <li>Postman</li>
                <li>Redux</li>
                <li>Express</li>
                <li>Firebase</li>
                <li>Bcrypt</li>
                <li>Massive</li>

            </ul>
        </SkillBox>
    )
}

export default ReactDeveloper