import { IntroPage,IntroBody,Introh2 } from "../Styles/intro.styles"
import ReactDeveloper from "./Skills/react.component"
import Maker from "./Skills/maker.styles"
import MachineLearning from "./Skills/data.component"
import { MyInfoBody,MyInfoPage } from "./myinfo.styles"

const reactLogo = {
    width:'150px',
    marginTop:'20px',
    paddingBottom:'60px'
}

const MyInfo = () => {

    return (
    
            <MyInfoPage>
                <img style={reactLogo} src='https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' />
                {/* <Introh2>Hi, I'm Chris. Nice to meet you.</Introh2>
                <p>
                After 15 years of tinkering with game developement, I discovered coding. I now build applications using the ReactJS/NodeJS framework, and make powerful numerical and data predictions using Python.
                </p> */}
                <h1>My tech stack</h1>
                <MyInfoBody>
                    <ReactDeveloper />
                    <MachineLearning />
                    <Maker />
                </MyInfoBody>
            </MyInfoPage>
    
    )
}

export default MyInfo