import { Hero,Portrait,IconRow } from "./hero.styles"
import ContactMe from "../ContactPage/ContactInfo.component"
// import { Notice } from "./hero.styles"
// import { Notice } from '../Form/Notice'
// import Notice from "./Form/Notice"
import photo from '../../assets/admin_photo1.jpg'
import { largeGitBash,contactIcon,resumeIcon} from "../Styles/large-svg"
import { palette } from "../Styles/colors"
import { useState,useEffect } from "react"
import routes from "../../routes"
import { Link } from "react-router-dom"
import { useContext } from "react"
import { AppContext } from "../Context/app.context"

const { deepBlue } = palette

const HeroView = () => {

    // const [contact,setContact] = useState(false)
    // const [notice,setNotice] = useState(null)
    const { contact,setContact } = useContext(AppContext)

    const handler = (param) => {
        console.log('hit handler in hero',param)
    }

    // const location = window.location.pathname.split('/')[1]
    const addressParam = window.location.pathname.split('/')[1]

    useEffect(() => {
        try {
            if (addressParam === 'contact') {
                setContact(true)
            }
        } catch (err) {
            console.log('there was an error',err)
        }
    },[])

    // console.log('this is the router', location.length)

    // useEffect(() => {
    //     try {
    //         if (location === 'contact') {
    //             setContact(true)
    //         }
    //     } catch (err) {
    //         console.log('there was an error',err)
    //     }
    // },[])

    // const addressParam = window.location.pathname.split('/')[1]

    // useEffect(() => {
    //     try {
    //         if (addressParam === 'contact') {
    //             setContact(true)
    //         }
    //     } catch (err) {
    //         console.log('there was an error',err)
    //     }
    // },[])

    // console.log('this is the router', location.length)

    // const changeNotice = (val) => {
    //     setNotice(val)
    // }

    return (
        <Hero>
            <h1>Developer, Maker, Citizen Scientist</h1>
            <p>I design and code beautifully simple things, and I love what I do.</p>
            {/* <Portrait><img src={'https://firebasestorage.googleapis.com/v0/b/depot-7bb3e.appspot.com/o/Chris%20deMontigny%2Fphotos%2FFB_IMG_1508768299982.JPEG?alt=media&token=1920934c-af9d-4e5b-986f-51ce89dd8a57'}/></Portrait> */}
            <Portrait><img src={photo}/></Portrait>
            {/* {contact &&
            <ContactMe
                setContact={setContact}
                changeNotice={changeNotice}
            />} */}
            {/* {routes} */}

            {/* {notice &&
            <Notice
                notice={notice}
                changeNotice={changeNotice}
            />} */}

            <IconRow>

            <a
                href="https://github.com/Nefari0"
                target="_blank"
            >
            {largeGitBash()}
            </a>

            <a
                // to={'/'}
                style={{color:`${deepBlue}`}}
                onClick={() => setContact(!contact)}
            >
            {contactIcon()}
            </a>

            <a
                style={{color:`${deepBlue}`}}
                href="https://docs.google.com/document/d/1vuy1o8nDqVc-_QHipp9tQ93P4V3L6B3H01vW2l-_FHA/edit"
                target="_blank"
            >
            {resumeIcon()}
            </a>
            </IconRow>
        </Hero>
    )
} 

export default HeroView