export const palette = {
    appBackground:'rgb(240, 240, 240)',
    darkText:'#000',
    white:'#fff',

    deepBlue:'#006eb5',
    lightBlue:'#00AAC2',

    lightGreen:'#99FFCC',
    midGreen:'#4ce3c6',
    darkGreen:'#00c7c4',
}